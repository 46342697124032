import React, { FunctionComponent, useEffect, useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import { BrowserBarcodeReader, Result } from "@zxing/library";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import { getOrders, orderShipped, getAllOrders } from "../reducers/orders";
import { getAllProducts } from "../reducers/products";
import Grid from "@material-ui/core/Grid";

import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      marginBottom: theme.spacing(2),
    },
  })
);

type ScanProps = {
  path: string;
  getAllOrders: any;
  getAllProducts: any;
  getOrders: any;
  user: any;
  products: any;
  orders: any;
};
const Scan: FunctionComponent<ScanProps> = ({
  orders,
  getAllProducts,
  getAllOrders,
  user,
  products,
  getOrders,
  path,
}) => {
  const codeReader = new BrowserBarcodeReader();
  let selectedDeviceId: any;
  const [code, setCode] = useState("");
  const [message, setMessage] = useState("");
  const [matchingOrder, setMatchingOrder] = useState({});
  const [showMessage, setShowMessage] = useState(false);

  const today = moment(Date.now());
  const classes = useStyles();
  useEffect(() => {
    const ordersFreshness = today.isSame(orders.lastFetch, "hour");
    if (
      user.isAuthenticated &&
      !user.loading &&
      !orders.loading &&
      (!ordersFreshness || orders.orders.length < 1)
    ) {
      getData();
    }
    return () => {
      codeReader.reset();
    };
  }, [codeReader]);
  const getData = () => {
    if (products.products.length === 0) {
      getAllProducts();
    }
    getOrders(() => {});
  };
  const renderPrompt = () => {
    const markOrdersShipped = () => {
      // @ts-ignore
      if (matchingOrder["combinedOrder"]) {
        setCode(code + "marked 2 as shipped");
        codeReader.reset();
      } else {
        setCode(code + "marked as shipped");
        codeReader.reset();
      }
    };

    if (showMessage) {
      return (
        <>
          <p>{message}</p>
          <br />
          <button
            onClick={markOrdersShipped}
            style={{ border: "1px solid black" }}
          >
            continue
          </button>
          <button
            onClick={() => {
              setShowMessage(false);
              setCode("");
              setMatchingOrder({});
              codeReader.reset();
            }}
            style={{ border: "1px solid black", background: "lightBlue" }}
          >
            continue
          </button>
        </>
      );
    }
  };
  const findAndMarkPackage = (tracking: any) => {
    const matchingOrder = orders.orders.find(
      (order: any) => order.tracking === tracking
    );
    if (matchingOrder) {
      setCode(matchingOrder.name);
      setMatchingOrder(matchingOrder);
      let confimationText = `
      please match name and address\n
      ${matchingOrder.name}
      on \n
      ${matchingOrder.address.street1}\n
      ${matchingOrder._id}\n
      `;
      if (matchingOrder.combinedOrder) {
        confimationText = `
      please match name and address\n
      ${matchingOrder.name}
      on \n
      ${matchingOrder.address.street1}\n
      ${matchingOrder._id}\n
      with a combine of ${matchingOrder.combinedOrder.name}
      `;
      }
      //setMessage(confimationText);
      //setShowMessage(true);
      const confirmation = window.confirm(confimationText);
      if (confirmation) {
        orderShipped(matchingOrder._id);
        setCode(code + "marked as shipped");
        if (matchingOrder.combinedOrder) {
          orderShipped(matchingOrder.combinedOrder._id);
          setCode(code + "marked 2 as shipped");
        }
        getOrders();
      }
    } else {
      setCode("no matching order");
    }
  };
  const startScaning = () => {
    try {
      codeReader.getVideoInputDevices().then((videoInputDevices) => {
        //setCode(JSON.stringify(videoInputDevices));
        if (videoInputDevices.length > 0) {
          selectedDeviceId = videoInputDevices[0].deviceId;
        }
      });
      codeReader
        .decodeOnceFromVideoDevice(selectedDeviceId, "video")
        .then((result) => {
          /* istanbul ignore next */
          //console.log("res", result);
          //setCode(code + result.text);
          // @ts-ignore
          const trackingNumber = result.text.substr(8);
          setCode(code + ` found ${trackingNumber}`);
          findAndMarkPackage(trackingNumber);
        })
        .catch((e) => {
          //console.log("e", e);
          setCode(JSON.stringify(e));
        });
    } catch (e) {}
  };

  if (orders.orders.length > 0) {
    return (
      <div className={classes.root}>
        <Grid container justify="center" spacing={2}>
          <button onClick={startScaning}> startScaning</button>

          {code}

          {renderPrompt()}
          <div id="scanner-container" style={{ width: "50vw" }}>
            <video
              width="300"
              id="video"
              className="dbrScanner-video"
              playsInline
            />
          </div>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            draggable
            pauseOnHover
          />
        </Grid>
      </div>
    );
  } else {
    return <div>loading</div>;
  }
};
const mapStateToProps = (state: any) => ({
  user: state.user,
  orders: {
    orders: state.order.orders,
    loading: state.order.loading,
  },
  products: state.product,
});

export default connect(mapStateToProps, {
  getOrders,
  getAllProducts,
  getAllOrders,

  orderShipped,
})(Scan);
