import React, { FunctionComponent, useEffect } from "react";
import { Modal } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { login, logout } from "../reducers/user";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: "50vw",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
type LoginProps = {
  handleClose: any;
  open: boolean;
  user: any;
  login: any;
  logout: any;
};
export const Login: FunctionComponent<LoginProps> = ({
  handleClose,
  open,
  user,
  login,
  logout,
}) => {
  useEffect(() => {
    if (!user.isAuthenticated && !user.loading) {
      const savedEmail = localStorage.getItem("e");
      const savedPass = localStorage.getItem("p");
      if (savedEmail && savedPass) {
        login({ email: savedEmail, password: savedPass });
      }
    }
  });
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [email, setEmail] = React.useState("");
  const [pass, setPass] = React.useState("");
  const sendLogin = () => {
    login({ email, password: pass });
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const setters = { password: setPass, email: setEmail };
    //@ts-ignore
    setters[event.target.name](event.target.value);
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <h2 id="simple-modal-title">Text in a modal</h2>
          <div>
            <TextField
              id="standard-basic"
              value={email}
              onChange={handleChange}
              label="email"
              name="email"
            />
            <TextField
              id="standard-basic"
              value={pass}
              onChange={handleChange}
              label="password"
              name="password"
            />
            <Button variant="contained" onClick={sendLogin} color="primary">
              Signin
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  user: state.user,
});

export default connect(mapStateToProps, { login, logout })(Login);
