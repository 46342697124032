// @ts-nocheck
import axios from "axios";
import {
  ADD_NOTIFICATION,
  GET_NOTIFICATIONS,
  GET_ORDERS,
  ORDERS_LOADING,
  GET_ORDER,
  ORDER_SHIPPED,
  LOGIN_FAILED,
} from "./types";
import { toast } from "react-toastify";
import { userToken } from "./user";

const initialState = {
  lastFetch: Date.now(),
  orders: [],
  order: {},
  loading: false,
};

export const getOrders = (cb) => async (dispatch, getState) => {
  console.log("orders");
  dispatch(setOrdersLoading());
  axios
    .get("/api/orders/", userToken(getState))
    .then((res) => {
      dispatch({
        type: GET_ORDERS,
        payload: res.data,
      });
      cb();
    })
    .catch((error) => {
      console.log("error in getting orders", error);
    });
};
export const getAllOrders = (cb) => async (dispatch, getState) => {
  dispatch(setOrdersLoading());
  axios
    .get("/api/orders/allthin", userToken(getState))
    .then((res) => {
      dispatch({
        type: GET_ORDERS,
        payload: res.data,
      });
      cb();
    })
    .catch((error) => {
      console.log(
        "error in getting orders",

        { ...error },
        Object.keys(error)
      );
      if (
        error.response &&
        error.response.status &&
        error.response.status === 401
      ) {
        dispatch({
          type: LOGIN_FAILED,
          payload: error,
        });
      }
    });
};

export const getOrder = (id) => async (dispatch, getState) => {
  const state = getState();
  const order = state.order.orders.find((o) => o._id === id);
  if (order) {
    dispatch({
      type: GET_ORDER,
      payload: order,
    });
  } else {
    dispatch(setOrdersLoading());
    axios
      .get("/api/orders/", userToken(getState))
      .then((res) => {
        dispatch({
          type: GET_ORDER,
          payload: res.data.find((o) => o._id === id),
        });
      })
      .catch((error) => {
        console.log("error in getting order", error);
      });
  }
};

export const rebuyShipping = (id) => async (dispatch, getState) => {
  axios
    .get(`/api/orders/check-rebuy/${id}`, userToken(getState))
    .then((res) => {
      console.log("this is an order", res.response);
      if (res.data.status === "valid") {
        toast.success(`good to ship ${res.data.message} `, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
      dispatch({
        type: GET_ORDER,
        payload: res.data.order,
      });
    })
    .catch((error) => {
      console.log("I have a problem shipping a order", error);
    });
};

export const orderShipped = (id) => async (dispatch, getState) => {
  axios
    .post(`/api/orders/shipped/${id}`, {}, userToken(getState))
    .then((res) => {
      dispatch({
        type: GET_ORDER,
        payload: res.data,
      });
    })
    .catch((error) => {
      console.log("I have a problem shipping a order", error);
    });
};

export const updateOrder = (id, updates, cb) => async (dispatch, getState) => {
  axios
    .patch(`/api/orders/update-order/${id}`, updates, userToken(getState))
    .then((res) => {
      dispatch({
        type: GET_ORDER,
        payload: res.data,
      });
      if (cb) {
        cb(null, res);
      }
    })
    .catch((error) => {
      if (cb) {
        cb(error, null);
      }
      console.log("I have a problem shipping a order", error);
    });
};

export const setOrdersLoading = () => ({ type: ORDERS_LOADING });
export default (state = initialState, action) => {
  switch (action.type) {
    case ORDERS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ORDERS:
      return {
        ...state,
        orders: action.payload,
        loading: false,
        lastFetch: Date.now(),
      };
    case GET_ORDER:
      return { ...state, order: action.payload, loading: false };
    default:
      return state;
  }
};
