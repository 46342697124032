import React, { FunctionComponent, useEffect, useState } from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import { getOrders, getAllOrders } from "../reducers/orders";
import { getAllProducts } from "../reducers/products";
import Grid from "@material-ui/core/Grid";
import SalesValues from "../components/todaysSale";
import TopSelling from "../components/topSelling";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      marginBottom: theme.spacing(2),
    },
  })
);

type SalesProps = {
  path: string;
  getAllOrders: any;
  getAllProducts: any;
  getOrders: any;
  user: any;
  products: any;
  orders: any;
};
const Sales: FunctionComponent<SalesProps> = ({
  orders,
  getAllProducts,
  getAllOrders,
  user,
  products,
  getOrders,
  path,
}) => {
  const today = moment(Date.now());
  const classes = useStyles();
  useEffect(() => {
    const ordersFreshness = today.isSame(orders.lastFetch, "hour");
    if (
      user.isAuthenticated &&
      !user.loading &&
      !orders.loading &&
      (!ordersFreshness || orders.orders.length < 1)
    ) {
      getData();
    }
  });

  const getData = () => {
    if (products.products.length === 0) {
      getAllProducts();
    }
    getAllOrders(() => {});
  };

  if (orders.orders.length > 0) {
    return (
      <div className={classes.root}>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={10}>
            <SalesValues time="month" orders={orders.orders} today={today} />
          </Grid>
          <Grid item xs={10}>
            <TopSelling
              time="month"
              orders={orders.orders}
              products={products.products}
              today={today}
            />
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return <div>loading</div>;
  }
};
const mapStateToProps = (state: any) => ({
  user: state.user,
  orders: {
    orders: state.order.orders,
    loading: state.order.loading,
  },
  products: state.product,
});

export default connect(mapStateToProps, {
  getOrders,
  getAllProducts,
  getAllOrders,
})(Sales);
