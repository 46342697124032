import React, { FunctionComponent, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      marginBottom: theme.spacing(2),
      maxHeight: "30vh",
      overflow: "scroll",
      flexWrap: "wrap",
      display: "flex",
      justifyContent: "space-between",
    },

    product: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2),
      width: "41%",
      textAlign: "center",
      fontSize: ".8em",
    },
  })
);
type OosProductsProps = {
  products: any;
  orders: any;
  today: any;
};

const OosProducts: FunctionComponent<OosProductsProps> = ({
  products,
  orders,
  today,
}) => {
  const classes = useStyles();

  const lastSold = (product: any) => {
    const lastOneSold = orders.reverse().find((order: any) => {
      return order.packingList.includes(product.name);
    });
    if (lastOneSold) {
      return moment(lastOneSold.createdAt).diff(today, "days");
    } else {
      return "err";
    }
  };
  const getLowStockProducts = () => {
    const lowStock = products.filter((product: any) => {
      return (
        10 > product.quantityInStock &&
        !product.hidden &&
        lastSold(product) > -20
      );
    });
    return lowStock;
  };
  const numSoldForProduct = (product: any) => {
    return orders.filter((order: any) => {
      const fromThisWeek = today.isSame(order.createdAt, "week");
      return fromThisWeek && order.packingList.includes(product.name);
    }).length;
  };
  const soldButNotShippded = (product: any) => {
    return orders.filter(
      (order: any) => !order.shipped && order.packingList.includes(product.name)
    ).length;
  };

  return (
    <Paper className={classes.paper}>
      <h2> low / oos products</h2>
      {getLowStockProducts().map((item: any) => (
        <Paper className={classes.product} key={item._id}>
          <p>
            {item.name} : {item.color}
          </p>

          <p>{item.quantityInStock} in stock </p>
          <p>{numSoldForProduct(item)} sold in the last week</p>
          <p>{soldButNotShippded(item)} sold but not shipped</p>
          {item.quantityInStock <= 0
            ? `last one sold ${lastSold(item)} days ago`
            : ""}
        </Paper>
      ))}
    </Paper>
  );
};

export default OosProducts;
