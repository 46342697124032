import React from "react";
import logo from "./logo.svg";
import "./App.css";
import "../node_modules/react-vis/dist/style.css";
import { Router } from "@reach/router";
//import "semantic-ui-css/semantic.min.css";
import Dashboard from "./pages/dashboard";
import Sales from "./pages/sales";
import Scan from "./pages/scan";
import Nav from "./components/nav";
function App() {
  return (
    <div
      style={{
        width: "100vw",
      }}
    >
      <Router>
        <Dashboard path="/" />
        <Sales path="/sales" />
        <Scan path="/scan" />
      </Router>
      <Nav />
    </div>
  );
}

export default App;
