// @ts-nocheck
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { rootReducer } from "./reducers/index";
import { composeWithDevTools } from "redux-devtools-extension";
import axios from "axios";
import moment from "moment";
axios.defaults.baseURL = "https://boardsource.xyz";
const loadState = () => {
  let state = {};
  try {
    if (localStorage.getItem("BoardSourceState") !== null) {
      localStorage.removeItem("BoardSourceState");
    }
    const cashedState = localStorage.getItem("BoardSourceState-v1.5");
    if (!cashedState) {
    } else {
      state = JSON.parse(cashedState);
    }
  } catch (error) {}
  return state;
};

const saveState = (state: any) => {
  try {
    localStorage.setItem("BoardSourceState-v1.5", JSON.stringify({ ...state }));
  } catch (error) {}
};
//todo turn this on turning off caching
const initialState = loadState();
// const initialState = {};

if (initialState.hasOwnProperty("product")) {
  initialState.order.loading = false;
}
if (initialState.hasOwnProperty("product")) {
  initialState.product.loading = false;
}
if (initialState.hasOwnProperty("user")) {
  const today = moment(Date.now());
  const loginWithinHour = today.isSame(initialState.user.loginTime, "hour");
  if (!loginWithinHour) {
    initialState.user.isAuthenticated = false;
  }
  initialState.user.didTry = false;
  initialState.user.populated = false;
}
const store = createStore(
  rootReducer(),
  initialState,
  composeWithDevTools(applyMiddleware(thunk))
);
store.subscribe(() => {
  const state = store.getState();
  saveState(state);
});

export default store;
