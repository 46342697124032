import { combineReducers } from "redux";
import userReducer from "./user";
import productReducer from "./products";
import ordersReducer from "./orders";
export const rootReducer = () =>
  combineReducers({
    user: userReducer,
    product: productReducer,
    order: ordersReducer,
  });

//import { combineReducers } from "redux";
//import keyboardsReducer, { InitialState } from "./keyboards";
//import keymapReducer, { Keymap } from "./keymap";
//const rootReducer = () =>
//combineReducers({ keyboards: keyboardsReducer, keymap: keymapReducer });

//export default rootReducer;
////export type RootState = ReturnType<typeof rootReducer>;
//export type RootState = {

//keyboards: InitialState;
//keymap: Keymap;
/*};*/
