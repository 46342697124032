import React, { FunctionComponent, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      marginBottom: theme.spacing(2),
      maxHeight: "50vh",
      overflow: "scroll",
      flexWrap: "wrap",
      display: "flex",
      justifyContent: "space-between",
      maxWidth: "100%",
    },

    product: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2),
      width: "41%",
      textAlign: "center",
      fontSize: ".8em",
    },
  })
);
type TopSellingProps = {
  products: any;
  orders: any;
  today: any;
  time: string;
};

const TopSelling: FunctionComponent<TopSellingProps> = ({
  products,
  orders,
  today,
  time,
}) => {
  const classes = useStyles();
  const getReleventOrdes = () => {
    //@ts-ignore
    return orders.filter((order: any) => today.isSame(order.createdAt, time));
  };
  const findTopSellingProduct = () => {
    const currentOrders = getReleventOrdes();
    let productSales = {};
    const fattyCatagories = ["Misc Part", "Switch", "keycap Set"];
    currentOrders.forEach((order: any) => {
      const productsSold = JSON.parse(order.packingList);
      Object.keys(productsSold).forEach((catagoryKey: string) => {
        productsSold[catagoryKey].forEach((product: any) => {
          //@ts-ignore
          if (productSales[`${product.name},${product.color}`]) {
            //@ts-ignore
            productSales[`${product.name},${product.color}`].amtSold +=
              catagoryKey === "Misc Part" ? 1 : product.qty;
          } else {
            //@ts-ignore
            productSales[`${product.name},${product.color}`] = {
              amtSold: catagoryKey === "Misc Part" ? 1 : product.qty,
              catagoryWeight: fattyCatagories.includes(catagoryKey) ? 0 : 1,
            };
          }
        });
      });
    });
    return Object.keys(productSales)
      .map((key: string) => ({
        name: key,
        //@ts-ignore
        amtSold: productSales[key].amtSold,
        //@ts-ignore
        catagoryWeight: productSales[key].catagoryWeight,
      }))
      .sort((a, b) => b.amtSold - a.amtSold)
      .slice(0, 20)
      .sort((a, b) => b.catagoryWeight - a.catagoryWeight);
  };
  const fillWithProducts = () => {
    const TopSellingProducts = findTopSellingProduct();
    return TopSellingProducts.map((product) => ({
      ...product,
      fullProduct: products.find(
        (fullProduct: any) =>
          fullProduct.name === product.name.split(",")[0] &&
          fullProduct.color === product.name.split(",")[1]
      ),
    }));
  };
  return (
    <Paper className={classes.paper}>
      <h2> Top selling products this {time}</h2>
      {fillWithProducts().map((item: any) => (
        <Paper className={classes.product} key={item._id}>
          <p>{item.name}</p>
          <p>{item.amtSold} sold</p>
          <p>
            {item.fullProduct ? item.fullProduct.quantityInStock : "NA"} left in
            Stock
          </p>
        </Paper>
      ))}
    </Paper>
  );
};

export default TopSelling;
