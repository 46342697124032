// @ts-nocheck
import axios from "axios";
import { toast } from "react-toastify";
import { getAllOrders } from "./orders";
import {
  USER_LOADING,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  UPDATE_USER,
  USER_CHECKOUT,
  LOGOUT,
  FAILED_GETTING_USER,
  FULL_USER,
} from "./types";

// import history from "../history";

const initialState = {
  loading: false,
  error: null,
  isAuthenticated: false,
  currentUser: {},
  needToFetch: false,
  didTry: false,
  populated: false,
  token: localStorage.getItem("token"),
};

export const login = ({ email, password }) => async (dispatch, getState) => {
  // console.log("trying to log in", email, password);

  dispatch(userLoading());
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ email: email.trim(), password });
  axios
    .post("/api/users/login", body, config)
    .then(async (res) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      getUserFull()(dispatch, getState);
      localStorage.setItem("e", email);
      localStorage.setItem("p", password);
      getAllOrders()(dispatch, getState);
    })
    .catch((err) => {
      console.log("got an err: ", err);
      dispatch({
        type: LOGIN_FAILED,
        payload: err,
      });
      toast.error("🦄 Oh No... Problem Logging In, check your logins?", {
        position: "top-center",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    });
};

export const resetPassword = ({ id, password, hash, cb }) => async (
  dispatch,
  getState
) => {
  console.log("reset", id, password, hash, cb);
  dispatch(userLoading());
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = { id, password, hash };
  axios
    .post("/api/users/changePassword", body, config)
    .then(async (res) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      getUserFull()(dispatch, getState);
      if (cb) {
        cb(null);
      }
    })
    .catch((err) => {
      console.log("got an err: ", err);
      dispatch({
        type: LOGIN_FAILED,
        payload: err,
      });
      if (cb) {
        cb(err);
      }
    });
};

export const getUser = () => (dispatch, getState) => {
  //? do we need this?
  const state = getState();
  if (!state.user.isAuthenticated && localStorage.getItem("token")) {
    dispatch({
      type: LOGIN_SUCCESS,
      payload: { token: localStorage.getItem("token"), user: {} },
    });
  }

  if (!state.user.isAuthenticated && !state.user.didTry) {
    dispatch(userLoading());
    axios
      .get(`/api/users/getuser`, userToken(getState))
      .then((res) =>
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data,
        })
      )
      .catch((error) =>
        dispatch({
          type: FAILED_GETTING_USER,
          payload: error.response,
        })
      );
  }
};

export const getUserFull = () => (dispatch, getState) => {
  const state = getState();
  // if (!state.user.populated && !state.user.didTry) {
  dispatch(userLoading());
  axios
    .get(`/api/users/getuser/full`, userToken(getState))
    .then((res) =>
      dispatch({
        type: FULL_USER,
        payload: res.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: FAILED_GETTING_USER,
        payload: error.response,
      })
    );
  // }
};

export const register = ({ email, password, name, userName }) => (
  dispatch,
  getState
) => {
  dispatch(userLoading());
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({
    email: email.trim(),
    password,
    name,
    userName,
  });
  axios
    .post("/api/users/signup", body, config)
    .then((res) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
    })
    .catch((error) => {
      console.log("got an err: ", error.response);
      dispatch({
        type: LOGIN_FAILED,
        payload: error.response,
      });
      switch (error.response.data.message) {
        case "email already in use":
          toast.error("Shucks that email is in use", {
            position: "top-center",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          break;
        case "User already exist!":
          toast.error("Shucks that username is taken", {
            position: "top-center",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        default:
          toast.error("problem creating an account try again later", {
            position: "top-center",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          break;
      }
    });
};

export const logout = () => (dispatch, getState) => {
  dispatch({
    type: LOGOUT,
    payload: false,
  });
};

const userLoading = () => ({
  type: USER_LOADING,
});

export const closeNotification = (notificationId) => (dispatch, getState) => {
  axios
    .post(
      `/api/notification/userclosed/${notificationId}`,
      {},
      userToken(getState)
    )
    .then((res) =>
      dispatch({
        type: FULL_USER,
        payload: res.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: FAILED_GETTING_USER,
        payload: error.response,
      })
    );
};

export const massEmail = (subject, message) => (dispatch, getState) => {
  axios
    .post(
      `/api/users/massemail`,
      { subject, body: message },
      userToken(getState)
    )
    .then((res) => {
      toast.info("mass Email Sent", {
        position: "top-center",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    })
    .catch((error) => {
      console.log("there was a problem sending emails", error);
      toast.error("error sending email", {
        position: "top-center",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    });
};

export const updateUserInfo = (updates, field) => (dispatch, getState) => {
  axios
    .patch(`/api/users`, updates, userToken(getState))
    .then((res) => {
      getUserFull()(dispatch, getState);
      toast.info(`${field} Updated!`, {
        position: "top-center",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    })
    .catch((error) => {
      console.log("there was a problem updating user", { ...error });
      if (error.response.data.error.codeName === "DuplicateKey") {
        toast.error(`${field} seems to be in use`, {
          position: "top-center",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        toast.error("Error updating your account", {
          position: "top-center",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    });
};

export const userToken = (getState) => {
  const token = getState().user.token;
  const conf = {
    headers: { "Content-type": "application/json" },
  };
  if (token) {
    conf.headers["x-auth-token"] = token;
  }
  // console.log(conf);

  return conf;
};

export const updateUser = (user) => (dispatch, getState) => {
  dispatch({ type: UPDATE_USER, payload: user });
};

export const userCheckOut = () => (dispatch, getState) => {
  dispatch({ type: USER_CHECKOUT });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER:
      return { ...state, user: action.payload, needToFetch: false };
    case USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      // console.log(action.payload, "I got a payload for the user");
      return {
        ...state,
        loading: false,
        token: action.payload.token,
        error: null,
        isAuthenticated: true,
        currentUser: action.payload.user,
      };
    case FULL_USER:
      return {
        ...state,
        loading: false,
        error: null,
        isAuthenticated: true,
        populated: true,
        currentUser: action.payload.user,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        error: action.payload,
      };
    case FAILED_GETTING_USER:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        error: action.payload,
        didTry: true,
      };
    case USER_CHECKOUT:
      return { needToFetch: true };
    case LOGOUT:
      localStorage.removeItem("token");
      return {
        ...state,
        loading: false,
        error: null,
        isAuthenticated: action.payload,
        currentUser: {},
        didTry: false,
        token: "",
      };
    default:
      return state;
  }
};
