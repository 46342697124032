import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import HomeIcon from "@material-ui/icons/Home";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";
import { navigate } from "@reach/router";
import Login from "./login";
const useStyles = makeStyles({
  root: {
    width: "100vw",
    bottom: 0,
    position: "absolute",
  },
});

export default function Nav() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const routes = ["", "sales", "scan"];
  const getCurrentRoute = () => {
    console.log(window.location);
    const currentIndex = routes.indexOf(window.location.pathname.substring(1));
    return currentIndex !== -1 ? currentIndex : 0;
  };
  const [value, setValue] = React.useState(getCurrentRoute());
  const returnButton = () => {
    const loginDetails = localStorage.getItem("login");
    if (!loginDetails) {
      return (
        <BottomNavigationAction label="login" icon={<DeveloperModeIcon />} />
      );
    }
  };
  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        if (newValue === 3) {
          handleOpen();
        } else {
          navigate(`/${routes[newValue]}`);
        }
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction label="Dash" icon={<HomeIcon />} />
      <BottomNavigationAction label="Sales" icon={<TrendingUpIcon />} />
      <BottomNavigationAction label="Scan" icon={<DeveloperModeIcon />} />
      <BottomNavigationAction label="login" icon={<DeveloperModeIcon />} />
      <Login handleClose={handleClose} open={open} />
    </BottomNavigation>
  );
}
