//@ts-nocheck
import React, { FunctionComponent, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import moment from "moment";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      marginBottom: theme.spacing(2),
    },
  })
);
type OrdersToShipProps = {
  orders: any;
  today: any;
};

const OrdersToShip: FunctionComponent<OrdersToShipProps> = ({
  orders,
  today,
}) => {
  const classes = useStyles();
  const [daysOrders, setDaysOrders] = useState([]);
  useEffect(() => {
    if (daysOrders.length === 0) {
      unshippedDaysOrders();
    }
  });

  const unshippedDaysOrders = () => {
    const todaysOrdersTemp: any = orders.filter((order: any) => {
      const fromToday = today.isSame(order.createdAt, "week");
      return fromToday && !order.packingList.includes("sembly");
    });
    setDaysOrders(todaysOrdersTemp);
    return todaysOrdersTemp;
  };
  return (
    <Paper className={classes.paper}>
      <h2>{daysOrders.length} orders To ship Today</h2>
    </Paper>
  );
};

export default OrdersToShip;
