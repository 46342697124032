import React, { FunctionComponent, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import moment from "moment";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      marginBottom: theme.spacing(2),
    },
  })
);
type SalesValuesProps = {
  orders: any;
  today: any;
  time: string;
};

const SalesValues: FunctionComponent<SalesValuesProps> = ({
  orders,
  today,
  time,
}) => {
  const classes = useStyles();
  const [todaysOrders, setTodaysOrders] = useState([]);
  const [yesterdaysOrders, setYesterdaysOrders] = useState([]);
  const [yesterdaysSales, setYesterdaysSales] = useState(0);
  const [todaysSales, setTodaysSales] = useState(0);
  const [currentOrderCount, setCurrentOrderCount] = useState(0);
  useEffect(() => {
    if (currentOrderCount !== orders.length) {
      setCurrentOrderCount(orders.length);
      getYesterdaysSales();
      getTodaysSales();
    }
  });
  //@ts-ignore
  const getTodaysOrders: any[] = () => {
    const todaysOrdersTemp: any = orders.filter((order: any) => {
      const fromToday = today.isSame(order.createdAt, time);
      return fromToday;
    });
    setTodaysOrders(todaysOrdersTemp);
    return todaysOrdersTemp;
  };
  const getTodaysSales = () => {
    //@ts-ignore
    const totalIs: any = getTodaysOrders().reduce(
      (total: any, next: any) => total + next.grandTotal,
      0
    );
    setTodaysSales(totalIs / 100);
  };
  const getYesterdaysSales = () => {
    //@ts-ignore
    const totalIs: any = getYesterdaysOrders().reduce(
      (total: any, next: any) => total + next.grandTotal,
      0
    );
    setYesterdaysSales(totalIs / 100);
  };
  const getYesterdaysOrders = () => {
    //@ts-ignore
    const yesterday = moment().subtract(1, time);
    const yesterdaysOrdersTemp: any = orders.filter((order: any) => {
      //@ts-ignore
      const fromYesterday = yesterday.isSame(order.createdAt, time);
      return fromYesterday;
    });
    setYesterdaysOrders(yesterdaysOrdersTemp);
    return yesterdaysOrdersTemp;
  };

  return (
    <Paper className={classes.paper}>
      <h2>
        {time}'s sales : ${todaysSales.toLocaleString()}
      </h2>
      <h2>
        Yester{time}'s sales : ${yesterdaysSales.toLocaleString()}
      </h2>
    </Paper>
  );
};

export default SalesValues;
