import React, { FunctionComponent, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { getOrders, getAllOrders } from "../reducers/orders";
import { getAllProducts } from "../reducers/products";
import Grid from "@material-ui/core/Grid";
import SalesValues from "../components/todaysSale";
import NextAssembly from "../components/nextAssembly";
import OrdersToShip from "../components/ordersToShip";
import OosProducts from "../components/oosProducts";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      marginBottom: theme.spacing(2),
    },
  })
);

type DashboardProps = {
  path: string;
  getAllOrders: any;
  getAllProducts: any;
  getOrders: any;
  user: any;
  products: any;
  orders: any;
};

const Dashboard: FunctionComponent<DashboardProps> = ({
  orders,
  getAllProducts,
  getAllOrders,
  user,
  products,
  path,
  getOrders,
}) => {
  const today = moment(Date.now());
  const [firstLoad, setfirstLoad] = useState(true);
  useEffect(() => {
    const ordersFreshness = today.isSame(orders.lastFetch, "hour");
    if (
      user.isAuthenticated &&
      !user.loading &&
      !orders.loading &&
      (!ordersFreshness || orders.orders.length < 1)
    ) {
      getData();
    }
    setfirstLoad(false);
  });
  const getData = () => {
    const productFreshness = today.isSame(products.lastFetch, "day");
    if (products.products.length === 0 || !productFreshness) {
      getAllProducts();
    }
    getAllOrders(() => {});
  };

  const classes = useStyles();
  if (orders.orders.length > 0) {
    return (
      <div className={classes.root}>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={10}>
            <SalesValues time="day" orders={orders.orders} today={today} />
          </Grid>
          <Grid item xs={5}>
            <NextAssembly
              orders={orders.orders.filter((order: any) => !order.shipped)}
              today={today}
            />
          </Grid>
          <Grid item xs={5}>
            <OrdersToShip
              orders={orders.orders.filter((order: any) => !order.shipped)}
              today={today}
            />
          </Grid>
          <Grid item xs={10}>
            <OosProducts
              today={today}
              orders={orders.orders}
              products={products.products}
            />
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return <div>loading</div>;
  }
};

const mapStateToProps = (state: any) => ({
  user: state.user,
  orders: {
    orders: state.order.orders,
    loading: state.order.loading,
  },
  products: state.product,
});

export default connect(mapStateToProps, {
  getOrders,
  getAllProducts,
  getAllOrders,
})(Dashboard);
