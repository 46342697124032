//@ts-nocheck
import React, { FunctionComponent, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import moment from "moment";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      marginBottom: theme.spacing(2),
    },
  })
);
type NextAssemblyProps = {
  orders: any;
  today: any;
};

const NextAssembly: FunctionComponent<NextAssemblyProps> = ({
  orders,
  today,
}) => {
  const classes = useStyles();
  const [nextAssebly, setNextAssembly] = useState({});
  const [assemblyFromDate, setAssemblyFromDate] = useState([]);
  useEffect(() => {
    if (Object.keys(nextAssebly).length === 0) {
      findNextAssembly();
    }
  });
  const findNextAssembly = () => {
    const nextUpAssembly = orders.find((order: any) => {
      return order.packingList.includes("sembly");
    });
    if (nextUpAssembly) {
      setNextAssembly(nextUpAssembly);
      const nextAssemblyDate = moment(nextUpAssembly.createdAt);
      const allAsseblysFromDay = orders.filter((order: any) => {
        return (
          order.packingList.includes("sembly") &&
          nextAssemblyDate.isSame(order.createdAt, "day")
        );
      });
      console.log("finding", allAsseblysFromDay, nextUpAssembly, orders);
      setAssemblyFromDate(allAsseblysFromDay);
    }
  };
  return (
    <Paper className={classes.paper}>
      <h2>
        {assemblyFromDate.length} assembly with{" "}
        {moment(nextAssebly.createdAt).diff(today, "days") + 14} Days Left
      </h2>
    </Paper>
  );
};

export default NextAssembly;
